import React from "react";
import { graphql, Link } from "gatsby";

// import { withPreview } from "gatsby-source-prismic";
import { RichText } from "prismic-reactjs";
import SEO from "../components/seo";
import ProductCard from "../components/ProductCard";
import { _linkResolver } from "../core/utils";

export const pageQuery = graphql`
  query _AllProducts {
    allPrismicProduct {
      nodes {
        uid
      }
    }
    prismicShop {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                type
                uid
                data {
                  title {
                    text
                  }
                  reference
                  gutter
                  image_featured {
                    ...sharpThumbnail
                  }
                  # images {
                  #   image {
                  #     ...sharp
                  #   }
                  # }
                }
              }
            }
          }
        }
        message_success {
          raw
        }
      }
    }
  }
`;

const OrderSuccess = ({ path, pageContext, data }) => {
  const { template } = pageContext;
  const {
    meta_title,
    meta_description,
    meta_image,
    products,
    message_success,
  } = data.prismicShop.data;

  const length = products.length;
  // const length = 1;
  return (
    <div className='page-template page-order-success'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={template}
        page={true}
        documentClass=''
      />

      <div className='header px-sm md:px-md mb-sm md:absolute w-full hidden-sm'>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-site-name'></div>
          <div className='col-auto col-h1 uppercase'>
            <Link to={"/shop"}>SHOP</Link>
          </div>
          <div className='col-auto col-menu'>
            <RichText
              render={message_success.raw}
              linkResolver={_linkResolver}
            />
          </div>
        </div>
      </div>

      <div className='px-md'>
        <div className=' grid-center min-h-screen'>
          <div className=' products flex flex-wrap justify-center content-center items-baseline '>
            {products.map(({ product }, i) => (
              <ProductCard input={product} key={i} total={length} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
